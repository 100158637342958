import { Injectable, signal } from '@angular/core';
import { IGame, IGameActivity, IGameCategory, IGameProvider } from '@libs/interfaces';
import { MapResponseData } from '@libs/rxjs/operators.rxjs';
import { Observable, map, of, switchMap, tap } from 'rxjs';
import { assign } from 'lodash';
import { IFilters } from '@libs/store/searcher/model';
import { ApiService } from '../api.service';
import { CasinoStoreService } from '../store/casino-store.service';
import { DeviceService } from '../device.service';

@Injectable({
  providedIn: 'root',
})
export class GameSearcherService {
  gamesProviders = signal<IGameProvider[]>([]);
  constructor(
    private api: ApiService,
    private casinoStoreService: CasinoStoreService,
    private deviceService: DeviceService,
  ) {}
  /**
   * Sets the visibility of the searcher.
   * @param {boolean} show - A boolean indicating whether to show the searcher.
   */
  setSearcherShow(show: boolean) {
    this.casinoStoreService.setSearcherShow(show);
  }

  /**
   * Searches for games within a specific category.
   * @param {string} category - The category to search within.
   * @param {string} query - The search query.
   * @returns {Observable<IGame[]>} An observable of the search results.
   */
  searchCategoryGames(category: string, query: string) {
    return this.api.get(`games/search/${category}/${query}`).pipe(
      MapResponseData,
      tap((categorySearchGames: IGame[]) => this.casinoStoreService.addCategorySearchGames(categorySearchGames)),
    );
  }

  /**
   * Performs a general search for games based on the current filters.
   * @returns {Observable<IGame[]>} An observable of the search results.
   */
  search() {
    return this.casinoStoreService.selectFilters().pipe(
      tap(() => this.casinoStoreService.setLoading(true)),
      map((filter: IFilters) =>
        assign({}, filter, {
          category: filter.category?.category,
          provider: filter.provider?.provider,
          limit: 50,
        }),
      ),
      switchMap(payload =>
        payload.word || payload.provider || payload.category
          ? this.api.post(`games/search`, payload)
          : of({ data: [] }),
      ),
      map(({ data }) => data || []),
      tap((games: IGame[]) => this.casinoStoreService.setGames(games)),
      tap(() => this.casinoStoreService.setLoading(false)),
    );
  }

  /**
   * Clears the category games from the store.
   * @returns {Observable<never[]>} An empty observable array.
   */
  clearCategoryGames() {
    this.casinoStoreService.addCategorySearchGames([]);
    return of([]);
  }

  /**
   * Fetches endless games based on the current filters.
   * @param {number} num - The number of games to fetch (default: 6).
   * @returns {Observable<IGame[]>} An observable of the fetched games.
   */
  fetchEndlessGames(num = 6) {
    return this.casinoStoreService.selectFilters().pipe(
      switchMap((filter: IFilters) => this.api.get(`games/endless/${num}/${this.deviceService.deviceType}`)),
      MapResponseData,
    );
  }

  /**
   * Fetches the games activity.
   * @returns {Observable<IGameActivity[]>} An observable of the games activity.
   */
  fetchGamesActivity(): Observable<IGameActivity[]> {
    return this.api.get(`games/activity`).pipe(MapResponseData);
  }

  /**
   * Adds a game to the casino store.
   * @param {IGame} game - The game to be added.
   */
  addGame(game: IGame) {
    this.casinoStoreService.addGame(game);
  }

  /**
   * Fetches the complete details of a game by its ID.
   * @param {string} game_id - The ID of the game to fetch.
   * @returns {Observable<IGame>} An observable of the fetched game details.
   */
  fetchGameComplete(game_id: string): Observable<IGame> {
    return this.api.get(`games/game/complete/${game_id}`).pipe(MapResponseData);
  }

  /**
   * Updates a game with the given payload.
   * @param {string} game_id - The ID of the game to update.
   * @param {any} payload - The data to update the game with.
   * @returns {Observable<any>} An observable of the updated game data.
   */
  updateGame(game_id: string, payload: any) {
    return this.api.put(`games/game/update/${game_id}`, payload).pipe(MapResponseData);
  }
}
